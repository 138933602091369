<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/systemset/role' }">角色列表</el-breadcrumb-item>
                <el-breadcrumb-item>角色</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--内容-->
            <el-form v-loading="loading" size="medium" :label-width="this.env.label_width">
                <el-form-item label="角色名称">
                    <el-input v-if="form.is_admin!==1" v-model="form.role_name"></el-input>
                    <el-input v-else v-model="form.role_name" readonly></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-tree
                            check-strictly
                            :default-checked-keys="form.select_control"
                            class="region-all"
                            node-key="control_uuid"
                            ref="tree"
                            :data="control_data"
                            :props="props"
                            show-checkbox
                            default-expand-all
                            :expand-on-click-node="false"
                            @check-change="handleCheckChange">
                    </el-tree>
                </el-form-item>
                <div style="height: 30px;"></div>
                <el-form-item>
                    <el-button v-if="this.Tool.is_auth('systemset.role.issave') && form.is_admin!==1" size="medium"
                               type="primary" @click="save">保存
                    </el-button>
                    <el-button size="medium" @click="isreturn">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '角色',
            loading: true,
            role_uuid: '',
            control_data: [],            // 权限信息
            props: {
                label: 'control_name',
                children: 'childlist',
            },
            // 表单数据
            form: {
                role_name: '',
                role_control: [],            // 权限内容
                select_control: [],          // 选中的内容
            }
        }
    },
    // 创建
    created() {
        this.init()
        this.getcontrol()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 初始化
        init() {
            let role_uuid = this.$route.query.role_uuid
            if (role_uuid !== undefined) {
                this.role_uuid = role_uuid
                this.getinfo();
            } else {
                this.loading = false
            }
        },
        //查询角色信息
        getinfo() {
            let postdata = {
                api_name: "systemset.role.getinfo",
                token: this.Tool.get_l_cache('token'),
                role_uuid: this.role_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 获取权限信息
        getcontrol() {
            let postdata = {
                api_name: "systemset.role.getcontrol",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.control_data = json.data
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 更改权限内容
        handleCheckChange() {
            let CheckedKeys = this.$refs.tree.getCheckedKeys()
            let HalfCheckedKeys = this.$refs.tree.getHalfCheckedKeys()
            // console.log('选中的节点', CheckedKeys)
            // console.log('半选中节点', HalfCheckedKeys)
            this.form.role_control = HalfCheckedKeys.concat(CheckedKeys)
            this.form.select_control = CheckedKeys
        },
        // 保存
        save() {
            let postdata = {
                api_name: "systemset.role.issave",
                token: this.Tool.get_l_cache('token'),
                role_name: this.form.role_name,
                role_control: this.form.role_control,
                select_control: this.form.select_control,
            }

            // 判断是否有角色id
            if (this.role_uuid !== '') {
                postdata.role_uuid = this.role_uuid
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.$router.push({path: '/systemset/role'})    // 返回列表
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })

        },
        // 返回
        isreturn() {
            this.$router.push({path: '/systemset/role'})
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.region-all {

}

>>> .region-all .el-tree-node {
    display: flex;
    align-items: center;
}

>>> .region-all > .el-tree-node {
    /*align-items: center;*/
    border-bottom: 1px #DCDFE6 solid;
    padding-top: 10px;
    padding-bottom: 10px;
}

/*一级节点*/
>>> .region-all > .el-tree-node > .el-tree-node__content {

}

/*二级节点*/
>>> .region-all > .el-tree-node > .el-tree-node__children > .el-tree-node > .el-tree-node__content {
    /*background: #3a8ee6;*/

}

/*三级节点*/
>>> .region-all > .el-tree-node > .el-tree-node__children > .el-tree-node > .el-tree-node__children {
    /*background: #51e652;*/
    margin-top: 8px;
    margin-bottom: 8px;
}

>>> .region-all > .is-expanded > .is-expanded {
    /*border-left: 1px #DCDFE6 solid;*/
}

>>> .region-all > .el-tree-node > .el-tree-node__children > .el-tree-node > .el-tree-node__children {
    display: flex;
    flex-wrap: wrap;
}

/*   >>> .region-all >.el-tree-node .el-tree-node__children >div[tabindex="-1"] {
       border-bottom: 1px #DCDFE6 solid;
       padding-top: 10px;
       padding-bottom: 10px;
   }*/


>>> .region-all .el-tree-node__label {
    /*width: 200px;*/
    min-width: 100px;
}

/*隐藏展开按钮*/
>>> .region-all .el-tree-node__expand-icon {
    display: none !important;

}


</style>
